import { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../utils/userAuthConfig";
import { getQLToken, getQLTokenTV } from "../utils/qlUserAuth";

const useStateWithSessionStorage = localSessionKey => {
    const [value, setValue] = useState(
        sessionStorage.getItem(localSessionKey) || ''
    );

    useEffect(() => {
        sessionStorage.setItem(localSessionKey, value);
    }, [value, localSessionKey]);

    return [value, setValue];
};


function useQLKey(permission) {


    const [sessionQLKey, setSessionQLKey] = useStateWithSessionStorage(
        'qlKEY'
    );

    // const onChange = event => setSessionQLKey(event.target.value);

    const { instance, accounts } = useMsal();
    // const [qlToken, setQlToken] = useState(null);

    

    useEffect(() => {

        const handleQLTokenUpdate = token => {
            // setQlToken(token);
            setSessionQLKey(token);
        }

        if (!sessionQLKey || sessionQLKey === '') {

            if (permission === 'onTV') {
                getQLTokenTV(permission).then((qlTokenResponse) => {
                    if (qlTokenResponse) {
                        handleQLTokenUpdate(qlTokenResponse);
                    }
                }
                ).catch((err) => {
                    console.warn(err)
                });

            } else {

                // RequestMsAuthInfo()
                instance.acquireTokenSilent({
                    ...loginRequest,
                    account: accounts[0]
                }).then((msAuthResponse) => {
                    // call backend api for ql key
                    getQLToken(msAuthResponse, permission).then((qlTokenResponse) => {
                        if (qlTokenResponse) {
                            handleQLTokenUpdate(qlTokenResponse);
                        }
                    }
                    ).catch((err) => {
                        console.warn(err)
                    });
                });

            }

        } else {
            // handleQLTokenUpdate(sessionQLKey);
            // setQlToken(sessionQLKey);
        }


        return () => {
            return null;
        };
    }, [accounts, instance, permission, sessionQLKey, setSessionQLKey]); // was [accounts, instance, permission]

    // return qlToken;
    return sessionQLKey;

}

export { useQLKey };
