import React, { useState } from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { loginRequest } from "../utils/userAuthConfig";
import { callMsGraph } from "../utils/graph";
import { ProfileData } from "./ProfileData";

/**
 * Renders information about the signed-in user or a button to retrieve data about the user
 */
const ProfileContent = () => {
    const { instance, accounts } = useMsal();
    const [graphData, setGraphData] = useState(null);

    function RequestProfileData() {
        // Silently acquires an access token which is then attached to a request for MS Graph data
        instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0]
        }).then((response) => {
            callMsGraph(response.accessToken).then(response => setGraphData(response));
        });
    }

    if (!graphData) RequestProfileData();

    return (
        <>
            <h5 className="card-title">User Profile Info For: {accounts[0].name}</h5>
            {graphData ?
                <ProfileData graphData={graphData} />
                :
                // <Button variant="secondary" onClick={RequestProfileData}>Request Profile Information</Button>
                <span>Loading...</span>
            }
        </>
    );
};

/**
* If a user is authenticated the ProfileContent component above is rendered. Otherwise a message indicating a user is not authenticated is rendered.
*/
export default function UserProfile() {
    return (
        <>
            <AuthenticatedTemplate>
                <ProfileContent />
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <h5 className="card-title">Please sign-in to see your profile information.</h5>
            </UnauthenticatedTemplate>
        </>
    );
};
