import { gql } from '@apollo/client';
import { tableName } from '../constants';

const UPDATE_PACKING_ORDER = gql`
    mutation updatePackingOrder($id: String!, $data: ${tableName}Input!) {
        ${tableName}Update(id: $id, data: $data) {
            id,
            bcID,
            productionOrderNumber,
            productCode,
            itemDescription,
            quantity,
            status,
            comments,
            packingDate,
            packingLines,
            site,
            priority,
            show,
            packingStatus
        }
    }
`;

export { UPDATE_PACKING_ORDER };
