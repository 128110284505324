import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    // Link
} from "react-router-dom";
// import { Helmet } from "react-helmet-async";
import OpsNav from "./OpsNav";
import PendingSales from "./PendingSales";
import OpsAdmin from "./OpsAdmin";
// import Home from "./Home";
import UserProfile from "./UserProfile";
// import TV from "./oldTV";
import TV from "./TV";

// import ApolloTable from "./ApolloTable";

// import AuthUser from "./AuthUser";

// function About() {
//     return <h2>About</h2>;
// }

// function Users() {
//     return <h2>Users</h2>;
// }

export default function AppRouter() {
    // console.log("test Router")
    return (
        <Router>

            <div className="App">
                {/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. */}
                <Switch>
                    <Route path="/users">
                        <OpsNav />
                        {/* <Users /> */}
                    </Route>
                    {/* <Route path="/pendingsales/:site">
                        <OpsNav />
                        <PendingSales />
                    </Route> */}
                    <Route path="/pendingsales">
                        <OpsNav />
                        <PendingSales />
                    </Route>
                    <Route path="/ops-admin">
                        <OpsNav />
                        <OpsAdmin />
                    </Route>
                    <Route path="/userprofile">
                        <OpsNav />
                        <UserProfile />
                    </Route>
                    <Route path="/tvpreview">
                        <OpsNav />
                        <TV />
                    </Route>
                    <Route path="/tv">
                        <TV />
                    </Route>
                    <Route path="/tv-pouring">
                        <TV scheduleType="pouring" />
                    </Route>
                    <Route path="/tv-flashing">
                        <TV scheduleType="flashing" />
                    </Route>
                    <Route path="/">
                        <OpsNav />
                        <OpsAdmin />
                    </Route>

                </Switch>
            </div>
        </Router>
    );
};