const apiPath = window.location.host === 'localhost:3000' ? '/' : '/api/'

async function getQLToken(msAuthResponse, permission) {
   
    const headers = new Headers();

    headers.append("MSAuth", JSON.stringify(msAuthResponse));
    headers.append("Permission", permission);

    const options = {
        method: "GET",
        headers: headers
    };

    try {
        const getTokenResponse = await fetch(apiPath + 'getqltoken', options);
        if (getTokenResponse.ok) {
            const qlToken = await getTokenResponse.json();
            return qlToken;
        }
        
    } catch (error) {
        throw error;
    }

}

async function getQLTokenTV(permission) {
   
    const headers = new Headers();

    headers.append("Permission", permission);

    const options = {
        method: "GET",
        headers: headers
    };

    try {
        const getTokenResponse = await fetch(apiPath + 'getqltoken-tv', options);
        if (getTokenResponse.ok) {
            const qlToken = await getTokenResponse.json();
            return qlToken;
        }
        
    } catch (error) {
        throw error;
    }

}

export { getQLToken, getQLTokenTV };
