import React, { useState, useEffect, useRef } from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import Select from 'react-select'
import { BsCheckSquareFill, BsSquare } from 'react-icons/bs'
import { useQLKey } from "../hooks/customAuthCheck";
import ApolloTable from "./ApolloTable";
import { getDayOfTheWeekToF, getTodayToF } from "../utils/dateTime";
import { packingTableCols } from '../data/packingTable'
import { colours, colourStyles, locations, packingLinesOptions, packingStatusOptions } from "../constants";
import { SelectCheckboxOption } from "./SelectCheckboxOption";


// TODO:
// Sort by Date oldest to newest ✔
// Filter by Date - Add date picker filter | https://www.npmjs.com/package/react-datepicker
// Filter by Line - DropDownBox
// Filter by Completed - TickBox
// Filter by Site - DropDown box
//

const isEmptyObj = (obj) => (
    // because Object.keys(new Date()).length === 0;
    // we have to do some additional check
    obj // 👈 null and undefined check
    && Object.keys(obj).length === 0
    && Object.getPrototypeOf(obj) === Object.prototype
)

const FilterDropDown = ({ field, handleFilter }) => {
    const [selectedFitler, setSelectedFitler] = useState(null)
    let dropDownItems = []
    if (field === 'packingLines') {
        dropDownItems = packingLinesOptions
    }

    if (field === 'site') {
        // dropDownItems = [
        //     { value: 1, label: 'Chichester', color: colours[0] },
        //     { value: 2, label: 'Fernhurst', color: colours[1] }
        // ]
        dropDownItems = locations
    }

    if (field === 'packingStatus') {
        dropDownItems = packingStatusOptions
    }

    if (field === 'show') {
        dropDownItems = [
            // { value: -1, label: 'Clear' },
            { value: 1, label: <>Yes <BsCheckSquareFill /></>, color: colours[0] },
            { value: 0, label: <>No <BsSquare /></>, color: colours[1] }
        ]
    }

    const handleFilterSelect = (input) => {
        setSelectedFitler(input);
        handleFilter(field, input)
    }

    return <Select
        isMulti={field === 'packingLines' ? true : false}
        name={`${field}-filter`}
        options={dropDownItems}
        defaultValue={selectedFitler}
        onChange={handleFilterSelect}
        closeMenuOnSelect={field === 'packingLines' ? false : true}
        hideSelectedOptions={false}
        components={field === 'packingLines' ? {
            Option: SelectCheckboxOption
        } : null}
        className="filter-multi-select w-100 form-control form-control-sm"
        classNamePrefix="select"
        placeholder="Filter..."
        menuPortalTarget={document.body}
        styles={colourStyles}
        isClearable

    />
}

const FilterText = ({ field, handleFilter }) => {
    const inputRef = useRef()
    const [filterText, setFilterText] = useState('%%')

    const handleFitlerText = (e) => {
        const thisValue = e.target.value
        setFilterText(thisValue)
        handleFilter(field, thisValue)
    }

    const handleFocus = (e) => {
        const thisValue = e.target.value
        if (thisValue === '%%') {
            inputRef.current.setSelectionRange(1,1)
        }
    }

    const toolTipText = ()=>{
        if (field === 'productionOrderNumber') {
            return "% = Wildcard/Any character, i.e. '%11%' would find 'BD112883'"
        }

        // if (field === 'itemDescription') {
        //     return "% = Wildcard/Any character, i.e. '%candle%' would find 'TWC - Candle - Calm'"
        // }

        if (field === 'status') {
            return "% = Wildcard/Any character, i.e. 'Po%' would find 'Poured - 19/04'"
        }

        return "% = Wildcard/Any character, i.e. '%candle%' would find 'TWC - Candle - Calm'"
    }

    return <input
        id={`${field}-filter`}
        name={`${field}-filter`}
        onChange={handleFitlerText}
        value={filterText}
        className="w-100 form-control form-control-sm"
        placeholder="Filter..."
        title={toolTipText()}
        autoComplete={`${field.toLowerCase()} filter`}
        ref={inputRef}
        onFocus={handleFocus}
        onClick={handleFocus}
    ></input>
}

const FilterField = ({ col, handleFilter }) => {
    const fieldName = col.name

    if (fieldName === 'productionOrderNumber' || fieldName === 'itemDescription' || fieldName === 'status') {
        return <FilterText
            field={col.name}
            handleFilter={handleFilter}
        />
    } else if (fieldName === 'packingLines' || fieldName === 'site' || fieldName === 'packingStatus' || fieldName === 'show') {
        return <FilterDropDown
            field={col.name}
            handleFilter={handleFilter}
        />
    } else {
        return null
    }

}

const TableHeadFilters = ({ cols, handleFilter }) => (
    <thead>
        <tr>
            {cols.map(col => (
                col.show ?
                    <th key={`col-filter-${col.name}`}
                        className={
                            col.description
                                .split(" ")
                                .join("-")
                                .toLowerCase()
                        }
                    >
                        {col.filterable ?
                            <FilterField
                                col={col}
                                handleFilter={handleFilter}
                            /> :
                            null
                        }
                    </th> :
                    null
            ))}
        </tr>
    </thead>
)

function OpsApolloTable({ apolloAuth, showDateFilter, showHeaderFitlers, showDataRowCount }) {
    const startDay = 'monday';
    const endDay = 'sunday';
    const ukF = 'cccc, dd/LL/yyyy';

    const [startDate, setStartDate] = useState(getDayOfTheWeekToF(startDay))
    const [endDate, setEndDate] = useState(getDayOfTheWeekToF(endDay))
    const [filteredProductionOrderNumber, setFilteredProductionOrderNumber] = useState('%%')
    const [filteredItemDescription, setFilteredItemDescription] = useState('%%')
    const [filteredStatus, setFilteredStatus] = useState('%%')
    const [filteredPackingLines, setFilteredPackingLines] = useState(null)
    const [filteredSite, setFilteredSite] = useState(null)
    const [filteredPackingStatus, setFilteredPackingStatus] = useState(null)
    const [filteredShow, setFilteredShow] = useState(null)
    const [dataRowCount, setDataRowCount] = useState(0)

    const handleSetDataRowCount = (count) => {
        setDataRowCount(count)
    }

    const handleStartDateChange = (e) => {
        setStartDate(e.target.value)
    }

    const handleEndDateChange = (e) => {
        setEndDate(e.target.value)
    }

    const handleFilterChange = (field, input) => {
        // console.log('filterChange:', field, input)

        // if (field === 'productionOrderNumber' && input.length > 0) {
        //     setFilteredProductionOrderNumber(input)
        // } else if (field === 'productionOrderNumber' && input.length === 0) {
        //     setFilteredProductionOrderNumber('')
        // }

        // if (field === 'itemDescription' && input.length > 0) {
        //     setFilteredItemDescription(input)
        // } else if (field === 'itemDescription' && input.length === 0) {
        //     setFilteredItemDescription('')
        // }

        // if (field === 'status' && input.length > 0) {
        //     setFilteredStatus(input)
        // } else if (field === 'status' && input.length === 0) {
        //     setFilteredStatus('')
        // }

        if (field === 'productionOrderNumber') {
            setFilteredProductionOrderNumber(input)
        }

        if (field === 'itemDescription') {
            setFilteredItemDescription(input)
        }

        if (field === 'status') {
            setFilteredStatus(input)
        }

        if (field === 'packingLines' && input.length > 0) {
            setFilteredPackingLines(input)
        } else if (field === 'packingLines' && input.length === 0) {
            setFilteredPackingLines(null)
        }

        if (field === 'site' && input) {
            setFilteredSite(input)
        } else if (field === 'site' && !input) {
            setFilteredSite(null)
        }

        if (field === 'packingStatus' && input) {
            setFilteredPackingStatus(input)
        } else if (field === 'packingStatus' && !input) {
            setFilteredPackingStatus(null)
        }

        if (field === 'show' && input) {
            setFilteredShow(input)
        } else if (field === 'show' && !input) {
            setFilteredShow(null)
        }
    }

    return (
        <div className="admin-container">
            {!isEmptyObj(apolloAuth['xc-token']) ?
                <>
                    <h2>Packing Line TVs</h2>
                    {showDateFilter ?
                        <div id="date-filter">
                                <label htmlFor="start-date">From:</label>
                                <input
                                    type="date"
                                    id="start-date"
                                    name="start-date"
                                    value={startDate}
                                    min="2020-01-01"
                                    max={endDate}
                                    // onChange={handleStartDateChange}
                                    onInput={handleStartDateChange}
                                    className="form-control"
                                />
                                <label htmlFor="end-date">To:</label>
                                <input
                                    type="date"
                                    id="end-date"
                                    name="end-date"
                                    value={endDate}
                                    min={startDate}
                                    max="2024-12-12"
                                    // onChange={handleEndDateChange}
                                    onInput={handleEndDateChange}
                                    className="form-control"
                                />
                        </div> :
                        <h3>From <span>{getDayOfTheWeekToF(startDay, ukF)}</span> to <span>{getDayOfTheWeekToF(endDay, ukF)}</span></h3>
                    }
                    { showDataRowCount ? <h6>{dataRowCount >= 100 ? <span className="warning">{dataRowCount} items loaded please fine tune filters to see things that may be missing!</span> : <span>{dataRowCount} items loaded.</span>}</h6> : null}
                </> :
                null
            }

            <div className="filtered-table-container">
                {showHeaderFitlers ?
                    <table className="packing-table-filters">
                        <TableHeadFilters cols={packingTableCols} handleFilter={handleFilterChange} />
                    </table> : null
                }
                <ApolloTable
                    // dateFrom={getDayOfTheWeekToF(startDay)}
                    // dateTo={getDayOfTheWeekToF(endDay)}
                    dateFrom={startDate}
                    dateTo={endDate}
                    // initialLimit={500}
                    pollInterval={2000}
                    allowEdit={true}
                    apolloAuth={apolloAuth}
                    productionOrderNumberFilter={filteredProductionOrderNumber}
                    itemDescriptionFilter={filteredItemDescription}
                    statusFilter={filteredStatus}
                    packingLinesFilter={filteredPackingLines}
                    siteFitler={filteredSite}
                    packingStatusFilter={filteredPackingStatus}
                    showFilter={filteredShow}
                    setDataRowCount={handleSetDataRowCount}
                    noMoreOrdersMessage={<>No orders found</>}
                />
            </div>
        </div>
    );
}

const OpsAdminTable = () => {

    const [today, setToday] = useState(getTodayToF());
    const currentKey = useQLKey("opsAdmin");
    const [apolloAuth, setApolloAuth] = useState(null);

    useEffect(() => {
        if (currentKey) {
            currentKey !== "NOT_ALLOWED" ?
                setApolloAuth({
                    'xc-token': currentKey
                })
                :
                setApolloAuth(currentKey)
        }

        return () => {
            return null;
        }
    }, [currentKey]); // was [currentKey]

    setInterval(() => {
        if (today !== getTodayToF()) {
            setToday(getTodayToF());
        };
    }, 60000);

    useEffect(() => {
        // 
    }, [today]);

    return apolloAuth ?
        apolloAuth !== "NOT_ALLOWED" ?
            <OpsApolloTable apolloAuth={apolloAuth} showDateFilter showHeaderFitlers showDataRowCount /> :
            <span>Access Denied</span> :
        <span>Loading...</span>
}


const OpsAdmin = () => (
    <>
        <AuthenticatedTemplate>
            <OpsAdminTable />
        </AuthenticatedTemplate>

        <UnauthenticatedTemplate>
            <h5 className="card-title">Please sign-in.</h5>
        </UnauthenticatedTemplate>
    </>

)

export default OpsAdmin;