import React from "react";
import { useState, useEffect } from 'react';
// import '../styles/Table.css';
import { DateTime } from "luxon";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import {
    // BrowserRouter as Router,
    Switch,
    Route,
    // Link,
    useParams,
    useRouteMatch
} from "react-router-dom";

// const getKeys = (data) => {
//   const output = [];
//   for (const key in data[0]) {
//     if (Object.hasOwnProperty.call(data[0], key)) {
//       // const element = data[0][key];
//       output.push(key);
//     }
//   }
//   return output;
// }

const toProperCase = function ($1) {
    return $1.toLowerCase().replace(/^(.)|\s(.)/g,
        function ($2) { return $2.toUpperCase(); });
}

const refreshIntervalMs = 1000 * 60 * 5; // 1000 MS * 60 * 5 = 5 Minutes
// let scroll = false;
// let lastScrollPos = 0;


function DateRow({ date, cols }) {

    const dt = DateTime.fromISO(date)
    const f = 'cccc, dd/LL/yyyy'
    const formatedPackingDate = dt.toFormat(f)

    return (
        <tr key={date}>
            <td colSpan={cols.filter(col => col.show).length} className='packing-date'>{formatedPackingDate}</td>
        </tr>
    )
}

const TableHeader = (props) => {
    const cols = props.cols;
    if (!cols) {
        throw Error("No data for Table Header!");
    }

    // console.log("cols", cols)

    return (
        <thead>
            <tr>
                {
                    cols.map((col, index) =>
                        col.show ? <th key={index}>{col.Description}</th> : null
                    )
                }
            </tr>
        </thead>
    );
}

const TableCell = (props) => {
    const col = props.col;
    const row = props.row;

    if (!col || !row) {
        throw Error("No data for cell!");
    }

    let cellText = row[col.Name];
    let cellProps;

    switch (col.Name) {
        case 'Shipment_Date':
            // cellText = DateTime.fromISO(cellText).toLocaleString();
            const dt = DateTime.fromISO(cellText);
            const f = 'dd/LL/yyyy';
            cellText = dt.toFormat(f);
            break;
        case 'ProductionOrderNo':
            cellProps = { className: 'prod-order-no' };
            break;
        case 'Quantity':
            cellProps = { className: 'quantity' };
            cellText = cellText.toLocaleString();
            break;
        default:
            // cellText = row[col.Name];
            break;
    }

    return <td {...cellProps}>{cellText}</td>
}

const TableRows = ({cols, rows}) => {

    if (!cols || !rows) {
        throw Error("No data for Table!");
    }

    let packingDate = null;

    const tableRows = rows.map((row) => {
        let dateRow = null;

        if (row.Shipment_Date !== packingDate) {
            packingDate = row.Shipment_Date
            dateRow = <DateRow date={packingDate} cols={cols} />
        }

        return (
            <>
                {dateRow}
                <tr key={row['@odata.etag']}>
                    {
                        cols.map((col, colIndex) =>
                            col.show ? <TableCell key={`${row['@odata.etag']}-${colIndex}`} col={col} row={row} /> : null
                        )
                    }
                </tr>
            </>
        )

    });

    return tableRows;
}

const TableBody = ({cols, rows}) => {

    if (!cols || !rows) {
        throw Error("No data for Table!");
    }

    return (
        <tbody>
            <TableRows cols={cols} rows={rows} />
        </tbody>
    );
}

// function delay(delayInms) {
//     return new Promise(resolve => {
//         setTimeout(() => {
//             resolve(2);
//         }, delayInms);
//     });
// }

// async function pageScroll(down) {

//     const scollEveryMs = 50;
//     const divider = 2;
//     const scrollHeight = document.getElementsByTagName('table')[0].scrollHeight / divider;

//     for (let i = 0; i < scrollHeight; i++) {
//         if (scroll) {
//             await delay(scollEveryMs);
//             down ? window.scrollBy(0, divider) : window.scrollBy(0, -divider);
//         }

//     }
//     pageScroll(!down);
// }

// const DevOverlay = (props) => {
//     const scrollHeight = props.scrollHeight;
//     return (
//         <div id='dev-overlay'>
//             <span>scrollHeight: <pre>{scrollHeight}</pre></span>
//         </div>
//     );
// }

const PendingSalesTable = () => {
    let { site } = useParams();

    // if (!site) {
    //     site = 'Chichester';
    // }

    // const [siteName, setSiteName] = useState(site);
    const [siteName] = useState(site);
    const [data, setData] = useState([]);
    // const [scrollHeight, setScrollHeight] = useState(null);

    // const initColsOLD = [
    //     { Name: "@odata.etag", Description: "ETag", show: false },
    //     { Name: "Document_Type", Description: "Document Type", show: false },
    //     { Name: "Document_No", Description: "Document No", show: false },
    //     { Name: "Line_No", Description: "Line No", show: false },
    //     { Name: "ProductionOrderNoSearch", Description: "Production Order No Search", show: false },
    //     { Name: "Shipment_Date", Description: "Packing Date", show: false },
    //     { Name: "ProductionOrderNo", Description: "Prod. Ord. No.", show: true },
    //     { Name: "CustomerName", Description: "Customer", show: true },
    //     { Name: "D365_Ext_Doc_No", Description: "Ext. Doc. No.", show: true },
    //     { Name: "No", Description: "No", show: false },
    //     { Name: "Item_No_2", Description: "Item No. 2", show: true },
    //     { Name: "ProductionOrderStatus", Description: "Production Order Status", show: false },
    //     { Name: "Description", Description: "Description", show: true },
    //     { Name: "Production_BOM_No_MOD08", Description: "Production BOM No", show: false },
    //     { Name: "ProductionOrderBlocked", Description: "Production Order Blocked", show: false },
    //     { Name: "D365_Site", Description: "Site", show: false },
    //     { Name: "Quantity", Description: "Quantity", show: true },
    //     { Name: "Unit_Price", Description: "Unit Price", show: false },
    //     { Name: "Amount", Description: "Amount", show: false },
    //     { Name: "SCB_Shortage_Status", Description: "Shortage Status", show: false },
    //     { Name: "D365_Status", Description: "Status", show: true },
    //     { Name: "Status", Description: "Status", show: false },
    //     { Name: "CommentsMOD19", Description: "Comments", show: false },
    //     { Name: "WicksMOD19", Description: "Wicks", show: false },
    //     { Name: "ShippersMOD19", Description: "Shipper", show: true },
    //     { Name: "Spec_Sheets", Description: "Spec Sheet", show: true },
    //     { Name: "Shipment_Date", Description: "Ship Date", show: true },
    //     { Name: "Created_ByMOD23", Description: "Created By", show: false },
    //     { Name: "SystemCreatedAt", Description: "System Created At", show: false },
    //     { Name: "SystemModifiedBy", Description: "System Modified By", show: false },
    //     { Name: "SystemModifiedAt", Description: "System Modified At", show: false },
    //     { Name: "TotalQty", Description: "Total Qty", show: false },
    //     { Name: "TotalAmount", Description: "Total Amount", show: false },
    // ];

    // console.log("initColsOLD, show: true", initColsOLD.filter((item) => item.show));

    const initCols = [
        { name: "@odata.etag", description: "ETag", show: false},
        { name: "Document_Type", description: "Document Type", show: false},
        { name: "Document_No", description: "Document No", show: false},
        { name: "Line_No", description: "Line No", show: false},
        { name: "ProductionOrderNoSearch", description: "Production Order No Search", show: false},
        { name: "Shipment_Date", description: "Shipment Date", show: true},
        { name: "CustomerName", description: "Customer", show: true},
        { name: "No", description: "No", show: false },
        { name: "ProductionOrderNo", description: "Prod. Ord. No.", show: true},
        { name: "D365_Ext_Doc_No", description: "Ext. Doc. No.", show: true},
        { name: "Description", description: "Description", show: true},
        { name: "D365_Site", description: "Site", show: false},
        { name: "D365_Status", description: "Status", show: true},
        { name: "Quantity", description: "Quantity", show: true},
        { name: "Planned_Delivery_date", description: "Planned Delivery Date", show: true},
        { name: "Unit_Price", description: "Unit Price", show: false},
        { name: "D365_Packing_Date", description: "Packing Date", show: true},
        { name: "Requested_Delivery_Date", description: "Requested Delivery Date", show: true},
        { name: "Promised_Delivery_Date", description: "Promised Delivery Date", show: true},
        { name: "Outbound_Warehouse_Handling_Time", description: "Outbound Warehouse Handling Time", show: true},
        { name: "Planned_Shipment_Date", description: "Planned Shipment Date", show: true},
        { name: "Shipping_Time", description: "Shipping Time", show: true},
        { name: "D365_Item_No_2", description: "Item No 2", show: true},
        { name: "ProductionOrderStatus", description: "Production Order Status", show: true},
        { name: "D365_Production_BOM_No", description: "D365 Production BOM No", show: true},
        { name: "ProductionOrderBlocked", description: "Production Order Blocked", show: true},
        { name: "Amount", description: "Amount", show: true},
        { name: "SCB_Shortage_Status", description: "SCB Shortage Status", show: true},
        { name: "Status", description: "Status", show: true},
        { name: "D365_Comments", description: "Comments", show: true},
        { name: "D365_Wicks", description: "Wicks", show: true},
        { name: "D365_Shippers", description: "Shippers", show: true},
        { name: "D365_Spec_Sheets", description: "Spec Sheets", show: true},
        { name: "D365A0B_Do_Not_Auto_ship_Rec", description: "D365A0B_Do_Not_Auto_ship_Rec", show: true},
        { name: "D365A0B_Ship_Rec_with_LineNo", description: "D365A0B_Ship_Rec_with_LineNo", show: true},
        { name: "D365_Created_ByMOD23", description: "D365_Created_ByMOD23", show: true},
        { name: "SystemCreatedAt", description: "SystemCreatedAt", show: true},
        { name: "SystemModifiedBy", description: "SystemModifiedBy", show: true},
        { name: "SystemModifiedAt", description: "SystemModifiedAt", show: true},
        { name: "TotalQty", description: "TotalQty", show: true},
        { name: "TotalAmount", description: "TotalAmount", show: true},
    ];

    // console.log("initCols, ALL", initCols)
    // console.log("initCols, show: true", initCols.filter((item) => item.show))

    if (!site) {
        // site = 'Chichester';
        // console.log('testObjAccess', initCols);
        initCols[11].show = true;
    } else {
        initCols[11].show = false;
    }
    const [cols] = useState(initCols);

    function getSiteData(siteName, whichLoad) {
        const siteNameStr = siteName ? siteName : 'All Sites';
        document.title = "Pending Sales | " + toProperCase(siteNameStr);

        const apiUrl = siteNameStr === 'All Sites' ? '/api/pendingsales' : `/api/pendingsales/site/${siteName}`

        // console.log(DateTime.now().toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS), " - " + whichLoad);
        fetch(apiUrl)
            .then(
                response => response.json()
            )
            .then(
                (data) => {
                    // console.log(data)
                    setData(data);
                    // console.log(DateTime.now().toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS), "- Data Recieived", data);
                    // scroll = true;
                    // pageScroll(true);
                }
            )
            .catch(err => console.error(err));
    }


    useEffect(() => {
        getSiteData(siteName, "First Data Load");
    }, [siteName]); // was [siteName]

    useEffect(() => {
        const interval = setInterval(() => {
            getSiteData(siteName, "Refresh Interval Start");
        }, refreshIntervalMs);
        return () => clearInterval(interval);
    }, [siteName]); // was [siteName]

    if (!cols || !data) {
        throw Error("No data for Table!");
    }


    // const keys = getKeys(data);

    // console.log("Keys:", keys);

    return (
        <>
            {/* <DevOverlay scrollHeight={scrollHeight} /> */}
            <table>
                <TableHeader cols={cols} />
                {
                    data
                        ? <TableBody cols={cols} rows={data} />
                        : <div className='loading'>Loading...</div>
                }

            </table>
        </>
    );
}

const PendingSales = () => {
    let { path } = useRouteMatch();
    return (
        <>
            <AuthenticatedTemplate>
                <Switch>
                    <Route exact path={path}>
                        <PendingSalesTable />
                    </Route>
                </Switch>
                <Switch>
                    <Route path={`${path}/:site`}>
                        <PendingSalesTable />
                    </Route>
                </Switch>
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <h5 className="card-title">Please sign-in to see the table.</h5>
            </UnauthenticatedTemplate>
        </>
    );
}

export default PendingSales;
