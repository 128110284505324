const toProperCase = function ($1) {
    return $1.toLowerCase().replace(/^(.)|\s(.)/g,
        function ($2) { return $2.toUpperCase(); });
}

function dupesInArray(array) {
    // Find duplicates using for loop
    let item_list = array;
    // console.log("keysTest", item_list)

    let duplicate = item_list.reduce((acc, currentValue, index, array) => {
        if (array.indexOf(currentValue) !== index && !acc.includes(currentValue)) acc.push(currentValue);
        return acc;
    }, []);

    if (duplicate.length) {
        console.log('Duplicate items are ' + duplicate.join(','));
    } else {
        // console.log('No duplicates!');
    }
    
}

export { toProperCase, dupesInArray };