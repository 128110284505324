import { DateTime } from "luxon";

const STANDARD_FORMAT = 'yyyy-LL-dd';

const now = () => { return DateTime.now().toISO()};

const ukDateToBCFormat = (ukDateString, bcF = STANDARD_FORMAT) => {

    const dt = DateTime.fromFormat(ukDateString, 'dd-LL-yyyy');
    let bcDate = dt.toFormat(bcF);

    return bcDate;
}

const daysFromDateToF = (days = 7, f = STANDARD_FORMAT, dt = DateTime.now()) => {
    let daysFromDateToF = dt.plus({ days: days}).toFormat(f);
    return daysFromDateToF;
}

const getDayOfTheWeekToF = (dayOfTheWeek, f = STANDARD_FORMAT, dt = DateTime.now()) => {
    const startOfTheWeek = dt.startOf('week');

    let dayOfTheWeekToF = startOfTheWeek;

    switch (dayOfTheWeek) {
        case 'monday':
            // dayOfTheWeekToF = startOfTheWeek;
            break;
        case 'tuesday':
            dayOfTheWeekToF = startOfTheWeek.plus({ days: 1 });
            break;
        case 'wednesday':
            dayOfTheWeekToF = startOfTheWeek.plus({ days: 2 });
            break;
        case 'thursday':
            dayOfTheWeekToF = startOfTheWeek.plus({ days: 3 });
            break;
        case 'friday':
            dayOfTheWeekToF = startOfTheWeek.plus({ days: 4 });
            break;
        case 'saturday':
            dayOfTheWeekToF = startOfTheWeek.plus({ days: 5 });
            break;
        case 'sunday':
            dayOfTheWeekToF = startOfTheWeek.plus({ days: 6 });
            break;
        default:
            break;
    }

    return dayOfTheWeekToF.toFormat(f);
}

const getTodayToF = (f = STANDARD_FORMAT, dt = DateTime.now()) => {
    const today = dt;

    return today.toFormat(f);
}

const getPackingTableViewDate = (date) => {
    const dt = DateTime.fromISO(date)
    const f = 'cccc, dd/LL/yyyy'
    return dt.toFormat(f)
}

const getTVTableHeaderDate = (dt = DateTime.now()) => {
    const f = 'ccc, dd LLL'
    return dt.toFormat(f)
}

const addDays = (date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

export { STANDARD_FORMAT, getDayOfTheWeekToF, daysFromDateToF, now, ukDateToBCFormat, getTodayToF, getPackingTableViewDate, getTVTableHeaderDate, addDays};
