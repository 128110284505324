import React from "react";
import Navbar from "react-bootstrap/Navbar";

import Nav from "react-bootstrap/Nav"
import Container from "react-bootstrap/Container"

import { Link } from "react-router-dom";
import { SignInButton } from "./SignInButton";
import { SignOutButton } from "./SignOutButton";
import { useIsAuthenticated } from "@azure/msal-react";

import "../styles/Navbar.scss"

import candleEmblem from "../images/emblem.png"


export default function OpsNav() {
    const isAuthenticated = useIsAuthenticated();
    // const isUserAllowed = null;

    const isAuthenticatedLinks = () => {
        if (isAuthenticated) {
            return (
                <>
                    <Link to="/" className="nav-link">Home</Link>
                    {/* <Link to="/pendingsales" className="nav-link">Pending Sales - All</Link>
                    <Link to="/pendingsales/chichester" className="nav-link">Pending Sales - Chichester</Link>
                    <Link to="/pendingsales/fernhurst" className="nav-link">Pending Sales - Fernhurst</Link> */}
                    {/* <Link to="/ops-admin" className="nav-link">Ops Admin</Link> */}
                    <Link to="/userprofile" className="nav-link">User Profile</Link>
                    <Link to="/tvpreview" className="nav-link">TV Preview</Link>
                </>
            )
        } else {
            return null
        }
    }
    return (

        <>
            <Navbar bg="dark" variant="dark" className="w-100">
                <Container>
                    <Navbar.Brand href="/">
                        <img
                            src={candleEmblem}
                            width="30"
                            height="30"
                            className="emblem d-inline-block align-top"
                            alt="Logo"
                        />
                        O.T.I.S
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            {isAuthenticatedLinks()}
                        </Nav>
                    </Navbar.Collapse>
                    {isAuthenticated ? <SignOutButton /> : <SignInButton />}
                </Container>
            </Navbar>
            <br />
        </>

    )
}