import React, { useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { DateTime } from 'luxon'
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    useQuery,
    useMutation,
} from '@apollo/client'
import Select from 'react-select'
import { SelectCheckboxOption } from './SelectCheckboxOption'
import {
    STANDARD_FORMAT,
    getDayOfTheWeekToF,
    getPackingTableViewDate,
    daysFromDateToF,
    // getTodayToF
} from '../utils/dateTime'
import { toProperCase } from '../utils/functions'
import { graphQLUrl, tableName, colourStyles, packingLinesOptions, packingStatusOptions, locations } from '../constants'
import { packingTableCols } from '../data/packingTable'
import { GET_PACKING_ORDERS } from '../gql/queries'
import { UPDATE_PACKING_ORDER } from '../gql/mutations'

// CSS
import '../styles/Table.scss'

const TableHead = ({ cols, onTV }) => (
    <thead>
        <tr>
            {cols.map(col => (
                col.show ?
                    <th key={`col-${col.name}`}
                        className={col.description
                            .split(" ")
                            .join("-")
                            .toLowerCase()}
                    >
                        {
                            onTV && col.tvHeader ?
                                col.tvHeader :
                                col.adminHeader || col.description
                        }
                    </th> :
                    null
            ))}
        </tr>
    </thead>
)

const TableCell = ({ col, row, allowEdit }) => {
    const inputRef = useRef()

    const value = row[col.name] === 99 ? '' : row[col.name] || ''
    const [currentValue, setCurrentValue] = useState(value)

    const [updatePackingOrderMutation] = useMutation(UPDATE_PACKING_ORDER)

    const handleCheckBoxChange = (e) => {
        const targetValue = e.target.checked ? 1 : 0
        setCurrentValue(targetValue)
        const tempData = {
            [col.name]: targetValue
        }
        updatePackingOrderMutation({
            variables: {
                id: row.id.toString(),
                data: tempData,
            }
        })
    }

    const handleFocus = (event) => event.target.select();

    const handleOnNumberChange = (event, col, row) => {
        setCurrentValue(event.target.value);
        const tempData = {
            [col.name]: parseInt(event.target.value)
        }
        updatePackingOrderMutation({
            variables: {
                id: row.id.toString(),
                data: tempData,
            }
        });
    }

    const getCurrentPackingLineOptions = () => {
        let options = []
        if (currentValue && currentValue !== '') {
            if (currentValue.includes(",")) {
                const values = currentValue.split(",")
                options = values.map(x => packingLinesOptions.find(y => y.value === x))
            } else {
                options = packingLinesOptions.find(y => y.value === currentValue)
            }
        }
        return options
    }

    const setCurrentPackingLineOptions = (selectedOptions) => {

        if (selectedOptions && selectedOptions.length >= 1) {
            const toValues = selectedOptions.map(option => option.value).join(',')
            setCurrentValue(toValues)
            const tempData = {
                [col.name]: toValues
            }
            updatePackingOrderMutation({
                variables: {
                    id: row.id.toString(),
                    data: tempData,
                }
            })
        } else if (selectedOptions.length === 0) {
            const toValues = ''
            setCurrentValue(toValues)
            const tempData = {
                [col.name]: toValues
            }
            updatePackingOrderMutation({
                variables: {
                    id: row.id.toString(),
                    data: tempData,
                }
            })
        }
    }

    const getCurrentPackingStatusOption = () => {
        let option = packingStatusOptions.find(x => x.value === currentValue)
        return option
    }

    const setCurrentPackingStatusOption = (selectedOption) => {
        let theValue = selectedOption ? selectedOption.value : '';

        setCurrentValue(theValue)
        const tempData = {
            [col.name]: theValue
        }
        updatePackingOrderMutation({
            variables: {
                id: row.id.toString(),
                data: tempData,
            }
        })

    }

    const hashZeroValue = (thisValue) => (
        thisValue === 99 ? '#' : thisValue || '#'
    )

    const getClassName = (colDescription, value) => {
        let className = ''
        if (colDescription === 'Batch') {
            className = 'production-order-number'
        } else {
            className = colDescription.split(" ").join("-").toLowerCase()
        }
        if (
            value === ''
            ||
            value === 0
            ||
            value === 99
            ||
            value === '#'
        ) {
            className += ' warning'
        } else {
            className += ' good'
        }
        return className
    }

    const generateCell = (col, row, allowEdit) => {

        const cellContents = (() => {
            switch (col.name) {
                case 'priority':
                    return allowEdit ?
                        <input
                            ref={inputRef}
                            type="number"
                            id={`${row.id}-priority-num`}
                            name={`${row.id}-priority-num`}
                            className="w-100 form-control form-control-sm"
                            placeholder="#"
                            value={currentValue}
                            min={1}
                            max={999}
                            onFocus={handleFocus}
                            onChange={(e) => {
                                handleOnNumberChange(e, col, row);
                            }}
                        /> :
                        hashZeroValue(value)
                case 'productionOrderNumber':
                    if (value.includes("|")) {
                        // more then one batch No., Split by |
                        return value
                            .split("|")
                            .sort((firstItem, secondItem) => firstItem - secondItem)
                            .map((text, index) => <span key={index}>{text}</span>)
                    } else if (value) {
                        // only one batch
                        return <span>{value}</span>
                    } else {
                        // no batch
                        break
                    }
                case 'quantity':
                    return value.toLocaleString()
                case 'packingLines':
                    return allowEdit ?
                        <Select
                            isMulti
                            name={`${row.id}-packing-line-nums`}
                            options={packingLinesOptions}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            components={{
                                Option: SelectCheckboxOption
                            }}
                            defaultValue={getCurrentPackingLineOptions}
                            onChange={setCurrentPackingLineOptions}
                            isClearable
                            menuPortalTarget={document.body}
                            styles={colourStyles}
                            className="packing-lines-multi-select"
                            classNamePrefix="select"
                        />
                        :
                        hashZeroValue(value)
                case 'site':
                    // return sites[row.site - 1].name
                    return locations[row.site - 1].name
                case 'packingStatus':
                    return allowEdit ?
                        <Select
                            name={`${row.id}-packing-status`}
                            options={packingStatusOptions}
                            closeMenuOnSelect
                            hideSelectedOptions={false}
                            defaultValue={getCurrentPackingStatusOption}
                            onChange={setCurrentPackingStatusOption}
                            isClearable
                            menuPortalTarget={document.body}
                            styles={colourStyles}
                            className="packing-status-select"
                            classNamePrefix="select"
                        />
                        :
                        value
                case 'show':
                    return <input
                        ref={inputRef}
                        type="checkbox"
                        id={`${row.id}-show-check`}
                        name={`${row.id}-show-check`}
                        checked={currentValue}
                        readOnly={!allowEdit}
                        disabled={!allowEdit}
                        onChange={handleCheckBoxChange}
                    />
                default:
                    return value
            }
        })()

        return [getClassName(col.description, currentValue), cellContents]
    }

    const [className, cellContents] = generateCell(col, row, allowEdit)

    return <td className={className}>{cellContents}</td>
}

const NormalRow = ({ cols, row, odd, allowEdit }) => (
    <tr className={odd ? "odd" : "even"}>
        {
            cols.map((col, index) => (
                col.show ? <TableCell key={col.name} col={col} row={row} allowEdit={allowEdit} /> : null
            ))
        }
    </tr>
)

const DateRow = ({ cols, date }) => (
    <tr className='packing-date'>
        <td colSpan={cols.filter(col => col.show).length}>{getPackingTableViewDate(date)}</td>
    </tr>
)


const DateThenNormalRow = ({ cols, row, odd, setDate, allowEdit }) => (
    <>
        {setDate && <DateRow cols={cols} date={row.packingDate} />}
        <NormalRow cols={cols} row={row} odd={odd} allowEdit={allowEdit} />
    </>
)


const TableRows = ({ cols, data, onTV, allowEdit, }) => {
    let packingDate = null // not using state
    let odd = true // not using state

    // const dataToSort = data.map(rec => rec)

    // const sortedData = dataToSort?.sort((a, b) => {
    //     const aProp = a.priority ? a.priority : 99
    //     const bProp = b.priority ? b.priority : 99
    //     return aProp - bProp
    // })

    const tableData = onTV
        ? data.map(rec => rec)?.sort((a, b) => {
            const aProp = a.priority ? a.priority : 99
            const bProp = b.priority ? b.priority : 99
            return aProp - bProp
        })
        : data

    const shouldSetDate = (row, onTV) => {
        // if on TV date row should never show as date is shown in page header.
        if (onTV) return false
        if (row.packingDate !== packingDate) {
            packingDate = row.packingDate
            return true
        }
        return false
    }

    const setOdd = (row) => {
        if (row.packingDate !== packingDate && !onTV) {
            odd = false
        } else if (onTV) {
            odd = !odd
        } else {
            odd = !odd
        }
        return odd;
    }

    return <tbody>
        {
            tableData.map((row) =>
                <DateThenNormalRow
                    key={row.id}
                    cols={cols}
                    row={row}
                    odd={setOdd(row)}
                    setDate={shouldSetDate(row, onTV)}
                    allowEdit={allowEdit}
                />
            )
        }
    </tbody>
}

const TableContainer = ({ cols, tableData, onTV, allowEdit }) => (
    <table className={onTV ? "packing-table on-tv" : "packing-table"}>
        <TableHead cols={cols} allowEdit={allowEdit} onTV={onTV} />
        <TableRows
            cols={cols} data={tableData} onTV={onTV} allowEdit={allowEdit}
        />
    </table>
)

const ErrorMessage = ({ error }) => {
    if (error.message === 'Access Denied : Please Login or Signup for a new account')
        return <span>Access Denied : Please send <Link to="/userprofile">profile info</Link> for approval.</span>

    return error.message
}



const PackingOrdersTable = ({
    offset,
    limit,
    where,
    sort,
    pollInterval,
    initialCols,
    gqlQry,
    onTV,
    allowEdit,
    noOrdersMessage,
    setDataRowCount
}) => {

    let currentLimit = limit

    const {
        loading,
        error,
        data,
        // fetchMore
    } = useQuery(
        gqlQry,
        {
            variables: {
                offset,
                limit: currentLimit,
                where,
                sort,
            },
            pollInterval: pollInterval,

        }
    )

    // query no. of items first
    // const handleFetchingMoreData = () => {
    //     const currentLength = data[tableName + 'List'].length
    //     fetchMore({
    //         variables: {
    //             offset: currentLength,
    //             limit: currentLimit,
    //             where,
    //             sort
    //         },
    //     })
    //     .then(fetchMoreResult => {
    //         currentLimit = currentLength + fetchMoreResult.data[tableName + 'List'].length
    //     })
    // }

    useEffect(() => {
        if (!onTV && !loading && !error)
            setDataRowCount(data[tableName + 'List'].length)

    }, [data, error, onTV, loading, setDataRowCount]);


    if (loading) return <h2>Loading...</h2>
    if (error) return <h2 className='error'>Error! <ErrorMessage error={error} /></h2>

    if (data[tableName + 'List'].length <= 0) return <h2>{noOrdersMessage}</h2>

    return (
        <div className={onTV ? 'tv-table' : null}>
            <TableContainer cols={initialCols} tableData={data[tableName + 'List']} onTV={onTV} allowEdit={allowEdit} />
            {/* {data[tableName + 'List'].length < limit ? null : <button onClick={handleFetchingMoreData}>Fetch More</button>} */}
            {/* { <button onClick={handleFetchingMoreData}>Fetch More</button> } */}
        </div>
    )
}

const newApolloClient = (auth) => {

    // // TODO: BREAKS SORT
    // const cache = new InMemoryCache({
    //     typePolicies: {
    //         Query: {
    //             fields: {
    //                 [tableName + 'List']: {
    //                     keyArgs: ["type"],

    //                     // While args.cursor may still be important for requesting
    //                     // a given page, it no longer has any role to play in the
    //                     // merge function.
    //                     merge(existing, incoming, { readField }) {
    //                       const merged = { ...existing };
    //                       incoming.forEach(item => {
    //                         merged[readField("id", item)] = item;
    //                       });
    //                       return merged;
    //                     },

    //                     // Return all items stored so far, to avoid ambiguities
    //                     // about the order of the items.
    //                     read(existing) {
    //                       return existing && Object.values(existing);
    //                     },
    //                   },
    //             }
    //         }
    //     }
    // })

    return new ApolloClient({
        uri: graphQLUrl,
        cache: new InMemoryCache(),
        // cache: cache,
        headers: auth,
    })
}

const ApolloTable = (
    {
        initialLimit = 100, // Apollo/GraphQL/SQL Max = 100
        pollInterval = 500,
        initialCols = packingTableCols,
        dateFrom = getDayOfTheWeekToF('monday'),
        dateTo = getDayOfTheWeekToF('sunday'),
        productionOrderNumberFilter = '%%',
        itemDescriptionFilter = '%%',
        statusFilter = '%%',
        packingLinesFilter,
        siteFitler,
        packingStatusFilter,
        showFilter,
        site,
        line,
        onTV,
        allowEdit,
        apolloAuth = {
            'xc-token': process.env.REACT_APP_NOCODB_XC_TOKEN
        }, // Default for Dev, wont be found in production
        noMoreOrdersMessage = <>No more orders for today!</>,
        setDataRowCount,
        scheduleType = '', // pouring|flashing|packing was 'packing'
    }
) => {

    const client = newApolloClient(apolloAuth);

    let colsToUse = initialCols

    const dateFromVal = dateFrom
    const dateToVal = dateTo

    const initWhereFilter = `~not(syncStatus,eq,removed)~and(packingDate,ge,${dateFromVal})~and(packingDate,le,${dateToVal})`
    let whereFilter = initWhereFilter
    // let whereFilter = `(packingDate,ge,2022-01-11)~and(packingDate,le,2022-01-11)`

    let productionOrderNumberFilterString,
        itemDescriptionFilterString,
        statusFilterString,
        packingLinesFilterString,
        siteFilterString,
        packingStatusFilterString,
        showFilterString;

    if (productionOrderNumberFilter === '') {
        productionOrderNumberFilterString = `~and(productionOrderNumber,eq,)`
    } else if (productionOrderNumberFilter !== '%%') {
        productionOrderNumberFilterString = `~and(productionOrderNumber,like,${productionOrderNumberFilter})`
    }

    if (itemDescriptionFilter === '') {
        itemDescriptionFilterString = `~and(itemDescription,eq,)`
    } else if (itemDescriptionFilter !== '%%') {
        itemDescriptionFilterString = `~and(itemDescription,like,${itemDescriptionFilter})`
    }

    if (statusFilter === '') {
        statusFilterString = `~and(status,eq,)`
    } else if (statusFilter !== '%%') {
        statusFilterString = `~and(status,like,${statusFilter})`
    }

    if (packingLinesFilter && packingLinesFilter.length > 0) {
        // const addToFilter = packingLinesFilter.map(item => `(packingLines,like,%${item.value}%)`)
        packingLinesFilterString = `~and(${packingLinesFilter.map(item => `(packingLines,like,%${item.value}%)`).join('~or')})`
    }

    if (siteFitler) {
        siteFilterString = `~and(site,eq,${siteFitler.value})`
    }

    if (packingStatusFilter) {
        packingStatusFilterString = `~and(packingStatus,eq,${packingStatusFilter.value})`
    }

    if (showFilter && showFilter.value !== -1) {
        showFilterString = `~and(show,eq,${showFilter.value})`
    }

    if (productionOrderNumberFilterString) {
        whereFilter += productionOrderNumberFilterString
    }
    if (itemDescriptionFilterString) {
        whereFilter += itemDescriptionFilterString
    }
    if (statusFilterString) {
        whereFilter += statusFilterString
    }
    if (packingLinesFilterString) {
        whereFilter += packingLinesFilterString
    }
    if (siteFilterString) {
        whereFilter += siteFilterString
    }
    if (packingStatusFilterString) {
        whereFilter += packingStatusFilterString
    }
    if (showFilterString) {
        whereFilter += showFilterString
    }


    // TODO: make Sortable from admin page
    const sortString = 'packingDate,priority'

    if (site || onTV) {

        if (onTV) {
            // if onTV remove show coloumn
            colsToUse = colsToUse.map((col) => (col.name === "show" || col.name === "status") ? { ...col, show: false } : col)
            // filter to today and if show === 1
            // whereFilter = `~not(syncStatus,eq,removed)~and(packingDate,eq,${getTodayToF()})~and(show,eq,1)` // Set to show just current day
            // daysFromDateToF(-daysFromNowQuery)
            // whereFilter = `~not(syncStatus,eq,removed)~and(packingDate,ge,${getDayOfTheWeekToF('monday')})~and(packingDate,le,${getDayOfTheWeekToF('sunday')})~and(show,eq,1)` // set to show current week and if show selected.
            const mondayBefore = daysFromDateToF(-7, STANDARD_FORMAT, DateTime.fromISO(getDayOfTheWeekToF('monday')))
            const sundayAfter = daysFromDateToF(7, STANDARD_FORMAT, DateTime.fromISO(getDayOfTheWeekToF('sunday')))
            whereFilter = `~not(syncStatus,eq,removed)~and(packingDate,ge,${mondayBefore})~and(packingDate,le,${sundayAfter})~and(show,eq,1)` // set to show previous week, current week and next week and if show selected.
        }

        if (site) {
            // if site remove site coloumn
            colsToUse = colsToUse.map((col) => col.name === "site" ? { ...col, show: false } : col)
            // convert to proper case
            const siteToProper = toProperCase(site)
            // get site number
            // const siteNumber = sites.find(({ name }) => name === siteToProper).number
            const siteNumber = locations.find(({ name }) => name === siteToProper).value
            // set filter to current site
            whereFilter += `~and(site,eq,${siteNumber})`
        }

        if (line) {
            // if line (packingLines) remove line coloumn
            colsToUse = colsToUse.map((col) => col.name === "packingLines" ? { ...col, show: false } : col)
            // set filter to current line
            // whereFilter += `~and(packingLine,eq,${line})`
            whereFilter += `~and(packingLines,like,%${line}%)`
        }

        // pouring|flashing|packing
        switch (scheduleType) {
            case 'pouring': 
                whereFilter += '~and(status,eq,)'
                break;
            case 'flashing':
                whereFilter += '~and(status,like,Po%)'
                break;
            case 'packing':
                whereFilter += '~and(~not(status,like,Po%))'
                break;

            default:
                break;
        }

    }

    const GQL_QRY = GET_PACKING_ORDERS(colsToUse)

    return (
        <ApolloProvider client={client}>
            <PackingOrdersTable
                offset={0}
                limit={initialLimit}
                sort={sortString}
                where={whereFilter}
                pollInterval={pollInterval}
                initialCols={colsToUse}
                gqlQry={GQL_QRY}
                allowEdit={allowEdit}
                onTV={onTV}
                noOrdersMessage={noMoreOrdersMessage}
                setDataRowCount={setDataRowCount}
            />
        </ApolloProvider>
    )
}

export default ApolloTable
