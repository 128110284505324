import chroma from "chroma-js";

const graphQLUrl = 'https://nocodb.thfholdings.com/nc/packingtvs_graphql_r9qm/v1/graphql';

const colours = [
    '#0d6efd',
    '#6610f2',
    '#6f42c1',
    '#d63384',
    '#dc3545',
    '#fd7e14',
    '#ffc107',
    '#198754',
    '#20c997',
    '#0dcaf0',
    '#0d6efd',
    '#6610f2',
    '#6f42c1',
    '#d63384',
    '#dc3545',
    '#fd7e14',
    '#ffc107',
    '#198754',
    '#20c997',
    '#0dcaf0',
]

const noOfPackingLines = 8;

const sites = [
    { number: 1, name: 'Chichester' },
    { number: 2, name: 'Fernhurst' },
    { number: 3, name: 'Portsmouth' },
];

//  - Manufacturing & Warehousing

const initLocations = [
    {
        code: 'CH-MW',
        name: 'Chichester',
    },
    {
        code: 'FE-MW',
        name: 'Fernhurst',
    },
    {
        code: 'PO-MW',
        name: 'Portsmouth',
    },
]

const locations = initLocations.map((location, indx) => ({
    ...location,
    number: indx + 1,
    value: indx + 1,
    label: location.name,
    color: colours[indx],
    longName: `${location.name} - Manufacturing & Warehousing`
}));

const tableName = process.env.REACT_APP_DB_PO_TABLE_NAME || 'packingOrders';

const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = chroma(data.color);
        return {
            ...styles,
            backgroundColor: isDisabled
                ? undefined
                : isSelected
                    ? data.color
                    : isFocused
                        ? color.alpha(0.1).css()
                        : undefined,
            color: isDisabled
                ? '#ccc'
                : isSelected
                    ? chroma.contrast(color, 'white') > 2
                        ? 'white'
                        : 'black'
                    : data.color,
            cursor: isDisabled ? 'not-allowed' : 'default',

            ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled
                    ? isSelected
                        ? data.color
                        : color.alpha(0.3).css()
                    : undefined,
            },
        };
    },
    multiValue: (styles, { data }) => {
        const color = chroma(data.color);
        return {
            ...styles,
            backgroundColor: color.alpha(0.1).css(),
        };
    },
    multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: data.color,
    }),
    multiValueRemove: (styles, { data }) => ({
        ...styles,
        color: data.color,
        ':hover': {
            backgroundColor: data.color,
            color: 'white',
        },
    }),
    menuPortal: base => ({ ...base, zIndex: 9999 }),
    menu: (provided, state) => ({
        ...provided,
        textAlign: 'center'
    }),
};

let packingLinesOptions = [...Array(noOfPackingLines)].map((x, i) => (
    { value: `${i + 1}`, label: `${i + 1}`, color: colours[i] }
))

packingLinesOptions = [
    ...packingLinesOptions,
    {
        value: `Cello`, label: `Cello`, color: colours[noOfPackingLines + 1]
    }
]

const packingStatusOptions = [
    { value: 'TD', label: 'TD', color: colours[0] },
    { value: 'Complete', label: 'Complete', color: colours[1] },
    { value: 'WIP', label: 'WIP', color: colours[2] }
]

export { graphQLUrl, noOfPackingLines, sites, tableName, colours, colourStyles, packingLinesOptions, locations, packingStatusOptions };
