import React from "react";
import './styles/App.scss';
import AppRouter from "./components/AppRouter";
import { HelmetProvider } from "react-helmet-async";

export default function App() {

  return (
    <HelmetProvider>
      <AppRouter />
    </HelmetProvider>
  );
};
