import { gql } from '@apollo/client';
import { tableName } from '../constants';

const GET_PACKING_ORDERS = (cols) => gql`
    query getPackingOrdersList($offset: Int, $limit: Int, $where: String, $sort: String) {
        ${tableName}List(offset: $offset, limit: $limit, where: $where, sort: $sort) {
            ${cols.map(col => col.name).join(",\r\n")}
        }		
    }
`

const GET_PACKING_ORDERS_COUNT = () => gql`
query GetPackingOrdersCount($where: String){
    packingOrdersCount(where: $where)
  }
`

export { GET_PACKING_ORDERS, GET_PACKING_ORDERS_COUNT };
