const packingTableCols = [
    {
        name: "id",
        description: "ID",
        show: false,
        type: "NumberID"
    },
    {
        name: "systemId",
        description: "SystemID",
        show: false,
        type: "TextID"
    },
    {
        name: "bcID",
        description: "BCID",
        show: false,
        type: "TextID"
    },
    {
        name: "priority",
        description: "Priority",
        show: true,
        type: "Priority",
        adminHeader: "Priority / No.",
        tvHeader: "No."
    },
    {
        name: "productionOrderNumber",
        description: "Production Order Number",
        show: true,
        type: "Text",
        adminHeader: "Production Order No. / Batch",
        tvHeader: "Batch",
        filterable: true
    },
    {
        name: "productCode",
        description: "Product Code",
        show: false,
        type: "Text",
    },
    {
        name: "itemDescription",
        description: "Item Description",
        show: true,
        type: "LongText",
        filterable: true
    },
    {
        name: "quantity",
        description: "Quantity",
        show: true,
        type: "Number",
        tvHeader: "QTY"
    },
    {
        name: "status",
        description: "Status",
        adminHeader: "BC Status",
        show: true,
        type: "Text",
        filterable: true
    },
    {
        name: "comments",
        description: "Comments",
        show: false,
        type: "LongText"
    },
    {
        name: "packingDate",
        description: "Packing Date",
        show: false,
        type: "Date"
    },
    {
        name: "packingLine",
        description: "Packing Line",
        show: false,
        type: "LineNumber"
    },
    {
        name: "packingLines",
        description: "Packing Lines",
        show: true,
        type: "LineNumbers",
        tvHeader: "Lines",
        filterable: true
    },
    {
        name: "site",
        description: "Site",
        show: true,        
        type: "SiteNumber",
        filterable: true
    },
    {
        name: "locationCode",
        description: "Location Code",
        show: false,
        type: "LocationText",
        filterable: true
    },
    {
        name: "packingStatus",
        description: "Packing Status",
        show: true,
        type: "Text",
        adminHeader: "Status",
        tvHeader: "Status",
        filterable: true
    },
    {
        name: "show",
        description: "Show",
        show: true,
        type: "CheckBox",
        filterable: true
    },
    
];

export { packingTableCols };
