import React from "react";
import { components } from "react-select";

export const SelectCheckboxOption = (props) => (
    <div>
        <components.Option {...props}>
            <input
                type="checkbox"
                checked={props.isSelected}
                onChange={() => null}
            />{" "}
            <label>{props.label}</label>
        </components.Option>
    </div>
)