import React, { useState, useEffect } from "react";
import {
    // BrowserRouter as Router,
    Switch,
    Route,
    // Link,
    useParams,
    useRouteMatch
} from "react-router-dom";

import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { useQLKey } from "../hooks/customAuthCheck";
import ApolloTable from "./ApolloTable";
import { getTodayToF, getTVTableHeaderDate } from "../utils/dateTime";
import candleEmblem from '../images/emblem.png'
import { toProperCase } from "../utils/functions";

function TVTableHeader({ line, site, isFirstTable = true }) {
    if (!site) {
        site = "All Sites";
    }

    return (
        <div className="tv-table-header">
            <div>{isFirstTable ? <h2>{getTVTableHeaderDate()}</h2> : null}</div>
            <div>{line ? <h1>Line {line}</h1> : <h1>{toProperCase(site)}</h1>}</div>
            <div>{isFirstTable ? <img src={candleEmblem} alt="Logo" /> : null}</div>
        </div>
    )
}


function OpsApolloTable({ site, line, onTV, style, isFirstTable, apolloAuth, scheduleType }) {

    return (
        <div style={style}>
            <TVTableHeader line={line} site={site} isFirstTable={isFirstTable} />

            <ApolloTable
                pollInterval={2000}
                site={site}
                line={line}
                onTV={onTV}
                apolloAuth={apolloAuth}
                scheduleType={scheduleType}
            />
        </div>
    );
}

function OpsTVTable({ site, onTV, scheduleType}) {
    let { line } = useParams();
    const perm = onTV ? "onTV" : "opsAdmin";
    const currentKey = useQLKey(perm);
    const [apolloAuth, setApolloAuth] = useState(null);

    useEffect(() => {
        if (currentKey) {
            currentKey !== "NOT_ALLOWED" ?
                setApolloAuth({
                    'xc-token': currentKey
                })
                :
                setApolloAuth(currentKey)
        }

        return () => {
            return null;
        }
    }, [currentKey]) // was [currentKey]

    if (apolloAuth && apolloAuth !== "NOT_ALLOWED") {
        if (line && line.includes(",")) {
            const lines = line.split(",");
            return (
                <div className="multi-table">
                    {
                        lines.map((thisLine, i) => {
                            const isFirstTable = i === 0 ? true : false;
                            const tableHeight = 100 / lines.length;

                            return <OpsApolloTable
                                key={i}
                                site={site}
                                line={thisLine}
                                onTV={true}
                                style={{ height: `${tableHeight}vh` }}
                                isFirstTable={isFirstTable}
                                apolloAuth={apolloAuth}
                                scheduleType={scheduleType}
                            />
                        })
                    }
                </div>
            );
        } else {
            return <OpsApolloTable
                site={site}
                line={line}
                onTV={true}
                apolloAuth={apolloAuth}
                scheduleType={scheduleType}
            />
        }
    } else if (apolloAuth === "NOT_ALLOWED") {
        return <h2>Access Denied</h2>
    } else {
        return <h2>Loading...</h2>
    }

}

function OpsTVTablePerSiteLine({ onTV, scheduleType }) {
    let { path } = useRouteMatch()
    let { site } = useParams()

    return (
        <>
            <Switch>
                <Route exact path={path}>
                    <OpsTVTable site={site} onTV={onTV} scheduleType={scheduleType} />
                </Route>
            </Switch>
            <Switch>
                <Route path={`${path}/:line`}>
                    <OpsTVTable site={site} onTV={onTV} scheduleType={scheduleType} />
                </Route>
            </Switch>
        </>
    );
}

const TV = ({scheduleType}) => {
    let { path } = useRouteMatch();
    const [today, setToday] = useState(getTodayToF());

    setInterval(() => {
        if (today !== getTodayToF()) {
            setToday(getTodayToF())
        }
    }, 60000)

    // useEffect(() => {
    //     // 
    // }, [today])

    return (
        <div className="tv-container">
            <AuthenticatedTemplate>
                <Switch>
                    <Route exact path={path}>
                        <OpsTVTable scheduleType={scheduleType} />
                    </Route>
                </Switch>
                <Switch>
                    <Route path={`${path}/:site`}>
                        <OpsTVTablePerSiteLine scheduleType={scheduleType} />
                    </Route>
                </Switch>
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <Switch>
                    <Route exact path={path}>
                        <OpsTVTable onTV={true} scheduleType={scheduleType} />
                    </Route>
                </Switch>
                <Switch>
                    <Route path={`${path}/:site`}>
                        <OpsTVTablePerSiteLine onTV={true} scheduleType={scheduleType} />
                    </Route>
                </Switch>
            </UnauthenticatedTemplate>
        </div>
    );
}

export default TV;
